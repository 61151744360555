import React from "react"
import { Helmet } from "react-helmet-async"

const Privacy = () => {
  return (
    <>
      <Helmet title="Privacy Policy - JiggSwap" />
      <div className="site-content content">
        <h1>Privacy Policy</h1>

        <p>Thanks for visiting JiggSwap!</p>
        <p>
          JiggSwap is an online platform designed to easily trade jigsaw puzzles with other people in the United States. Currently we're
          only guaranteeing support for users located in the continental United States, but we may start supporting other counties (or
          states!) as well in the future.
        </p>
        <p>
          This Privacy Policy is intended to inform you of what information JiggSwap may collect from you, what it will use the information
          for, and what actions you can take with regards to how your information is stored.
        </p>

        <h3>What information is collected?</h3>
        <p>
          JiggSwap may collect any of the following information:
          <ul>
            <li>Your Email Address</li>
            <li>Your First Name</li>
            <li>Your Last Name (or Initial)</li>
            <li>
              Your Postal Address, which includes:
              <ul>
                <li>Street Address</li>
                <li>City</li>
                <li>State</li>
                <li>Zip Code</li>
              </ul>
            </li>
          </ul>
        </p>

        <h3>What information is shared?</h3>
        <p>
          JiggSwap uses the information you provide in order to facilitate trading your puzzles with other users. Your information may be
          shared under the following conditions:
        </p>
        <b>Publicly available information</b>
        <p>
          The following personal information is publicly available on Jiggswap:
          <ul>
            <li>Your First Name</li>
            <li>Your State</li>
          </ul>
        </p>

        <b>Personal data you may choose to share</b>
        <p>
          By engaging in a trade with another user, the following personal information will be shared with your trade partner (in addition
          to the publicly available information described above):
          <ul>
            <li>Your Last Initial</li>
            <li>Your City</li>
            <li>Your Zip Code</li>
          </ul>
        </p>
        <b>Personal data that will always remain private</b>
        <p>
          The following information will never be shared to anyone, on or off of Jiggswap:
          <li>Your Email Address</li>
        </p>

        <h3>How can I remove my information?</h3>
        <p>
          If you would like to remove your information from Jiggswap, up to and including the deletion of your account, you may contact us
          at <a href="mailto:privacy@jiggswap.com">privacy@jiggswap.com</a> with your request.
        </p>

        <h3>Age Restrictions</h3>
        <p>
          Jiggswap is not directed to children under the age of 13 years. Jiggswap is also not offered to children whose age makes it
          illegal to process their personal data or requires parental consent for the processing of their personal data under local law.
        </p>
        <p>
          We do not knowingly collect personal data from children under 13 years or under the applicable age limit. If you are under the
          applicable age limit, please do not use Jiggswap, and do not provide any personal data to us.
        </p>
        <p>
          If we learn that we have collected the personal data of a child under the age of 13 years, we will take reasonable steps to delete
          the personal data. This may require us to delete the Jiggswap account for that child.
        </p>

        <h3>Changes</h3>
        <p>We may occasionally make changes to this Policy.</p>
        <p>
          When we make material changes to this Policy, we'll provide you with prominent notice as appropriate under the circumstances,
          e.g., by displaying a prominent notice on the site or by sending you an email.
        </p>

        <h3>How to Contact Us</h3>
        <p>Thank you for reading our privacy policy.</p>
        <p>
          If you have questions about this privacy policy, please contact us by emailing{" "}
          <a href="mailto:privacy@jiggswap.com">privacy@jiggswap.com</a>.
        </p>
        <p>© Jiggswap.</p>
      </div>
    </>
  )
}

export default Privacy
